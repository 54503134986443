<template>
  <b-sidebar
    id="banner-card-image-sidebar-story"
    :visible="visible"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:visible', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Configuración
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <div class="px-2 pt-2">
        <b-form-group
          label="Importar configuración"
          label-for="listConfig"
        >
          <v-select
            v-model="listConfigValue"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="listConfig"
            :clearable="false"
            input-id="listConfig"
            @input="($event) => handleImportConfig($event)"
          />
        </b-form-group>

        <hr>
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="px-2 pb-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Autor -->
          <validation-provider
            #default="validationContext"
            name="Autor"
          >
            <b-form-group
              label="Autor"
              label-for="author"
            >
              <b-form-input
                id="author"
                v-model="form.author"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="Tipo de historia"
            rules="required"
          >
            <b-form-group
              label="Tipo de historia"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="form.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                :clearable="false"
                label="name"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template v-if="form.type && form.type.value === 'Casino'">
            <!-- Título -->
            <validation-provider
              #default="validationContext"
              name="Título"
            >
              <b-form-group
                label="Título"
                label-for="btnText"
              >
                <b-form-input
                  id="btnText"
                  v-model="form.btnText"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Link -->
            <validation-provider
              #default="validationContext"
              name="Link"
            >
              <b-form-group
                label="Link"
                label-for="btnLink"
              >
                <b-form-input
                  id="btnLink"
                  v-model="form.btnLink"
                  :state="getValidationState(validationContext)"
                  type="url"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <!--  -->
          <template v-if="form.type && form.type.value === 'Deporte'">
            <p class="font-small-3 font-weight-bold mt-2 mb-25">Equipo local</p>
            <b-card class="border px-1 pb-1 pt-50" no-body>
              <b-row align-v="center">
                <b-col cols="12" md="8">
                  <!-- Equipo local -->
                  <validation-provider
                    #default="validationContext"
                    name="Nombre"
                    rules="required"
                  >
                    <b-form-group
                      label="Nombre"
                      label-for="homeTeam"
                      class="mb-25"
                    >
                      <b-form-input
                        id="homeTeam"
                        v-model="form.homeTeam"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Bandera local -->
                  <validation-provider
                    #default="validationContext"
                    name="Bandera local"
                  >
                    <b-form-group
                      label="Bandera local"
                      label-for="fileHomeFlag"
                      class="mb-0"
                    >
                      <b-form-file
                        id="fileHomeFlag"
                        ref="refInputHomeFlag"
                        :disabled="loading || loadingHomeFlag"
                        accept=".jpg, .png, .webp, .gif, .svg"
                        placeholder="Haga clic para subir la bandera"
                        @input="($event) => onChangeFile($event, 'homeFlag')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="4">
                  <div
                    class="icon-container d-flex justify-content-center cursor-pointer mt-50"
                    @click="handleFile('homeFlag')"
                  >
                    <app-image
                      :src="form.homeFlag"
                      alt="icon"
                      width="100px"
                      height="100px"
                    />
                    <div
                      v-if="loadingHomeFlag"
                      class="align-items-center d-flex flex-column h-100 justify-content-center position-absolute top-left w-100"
                    >
                      <b-spinner />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <p class="font-small-3 font-weight-bold mt-2 mb-25">Equipo visitante</p>
            <b-card class="border px-1 pb-1 pt-50" no-body>
              <b-row align-v="center">
                <b-col cols="12" md="8">
                  <!-- Equipo visitante -->
                  <validation-provider
                    #default="validationContext"
                    name="Nombre"
                    rules="required"
                  >
                    <b-form-group
                      label="Nombre"
                      label-for="visitingTeam"
                      class="mb-25"
                    >
                      <b-form-input
                        id="visitingTeam"
                        v-model="form.visitingTeam"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- Bandera visitante -->
                  <validation-provider
                    #default="validationContext"
                    name="Bandera visitante"
                  >
                    <b-form-group
                      label="Bandera visitante"
                      label-for="fileVisitingFlag"
                      class="mb-0"
                    >
                      <b-form-file
                        id="fileVisitingFlag"
                        ref="refInputVisitingFlag"
                        :disabled="loading || loadingVisitingFlag"
                        accept=".jpg, .png, .webp, .gif, .svg"
                        placeholder="Haga clic para subir la bandera"
                        @input="($event) => onChangeFile($event, 'visitingFlag')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="4">
                  <div
                    class="icon-container d-flex justify-content-center cursor-pointer mt-50"
                    @click="handleFile('visitingFlag')"
                  >
                    <app-image
                      :src="form.visitingFlag"
                      alt="icon"
                      width="100px"
                      height="100px"
                    />
                    <div
                      v-if="loadingVisitingFlag"
                      class="align-items-center d-flex flex-column h-100 justify-content-center position-absolute top-left w-100"
                    >
                      <b-spinner />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-row>
              <b-col cols="12" md="4">
                <!-- Cuota 1 -->
                <validation-provider
                  #default="validationContext"
                  name="Cuota 1"
                  rules="required"
                >
                  <b-form-group
                    label="Cuota 1"
                    label-for="fee1"
                  >
                    <b-form-input
                      id="fee1"
                      v-model.number="form.fee1"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <!-- Cuota X -->
                <validation-provider
                  #default="validationContext"
                  name="Cuota X"
                  rules="required"
                >
                  <b-form-group
                    label="Cuota X"
                    label-for="feeX"
                  >
                    <b-form-input
                      id="feeX"
                      v-model.number="form.feeX"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <!-- Cuota 2 -->
                <validation-provider
                  #default="validationContext"
                  name="Cuota 2"
                  rules="required"
                >
                  <b-form-group
                    label="Cuota 2"
                    label-for="fee2"
                  >
                    <b-form-input
                      id="fee2"
                      v-model.number="form.fee2"
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </template>

          <!-- Status -->
          <!-- <validation-provider
            name="Activo"
          >
            <b-form-group
              label="Activo"
              label-for="statusCheck"
            >
              <b-form-checkbox
                id="statusCheck"
                v-model="form.status"
                name="statusCheck"
              >
                {{ form.status ? 'Si' : 'No' }}
              </b-form-checkbox>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <template v-if="loading">
                <b-spinner
                  variant="light"
                  class="mr-75"
                  small
                />
                <span>{{ form.id ? 'Actualizando' : 'Creando' }}</span>
              </template>
              <span v-else>{{ form.id ? 'Actualizar' : 'Crear' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  // BFormCheckbox,
  BSpinner,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countriesList from '@/@fake-db/data/other/countries'
import AppImage from '@core/components/app-image/AppImage.vue'
import { useFileToBase64 } from '@core/comp-functions/forms/form-utils'
import GeneralRequest from '@/@api/general'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    // BFormCheckbox,
    BSpinner,
    BRow,
    BCol,
    BCard,
    vSelect,
    AppImage,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    fileList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countriesList,
    }
  },
  watch: {
    visible(val) {
      if (val && this.item) {
        this.form = {
          id: this.item.id,
          type: this.item.type,
          author: this.item.author,
          btnText: this.item.btnText,
          btnLink: this.item.btnLink,
          homeTeam: this.item.homeTeam,
          homeFlag: this.item.homeFlag || null,
          visitingTeam: this.item.visitingTeam,
          visitingFlag: this.item.visitingFlag || null,
          fee1: this.item.fee1,
          feeX: this.item.feeX,
          fee2: this.item.fee2,
          // status: this.item.status,
        }

        this.listConfig = this.fileList
          .filter(i => i.settings.type && i.settings.type.value)
          .map(i => ({
            label: `${i.name} - ${i.settings.type.value}`,
            value: JSON.stringify(i.settings),
          }))
      } else {
        // this.form = {
        //   id: null,
        //   type: '',
        //   author: '',
        //   btnText: '',
        //   btnLink: '',
        //   homeTeam: '',
        //   homeFlag: '',
        //   visitingTeam: '',
        //   visitingFlag: '',
        //   fee1: '',
        //   feeX: '',
        //   fee2: '',
        //   // status: true,
        // }
        // this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      type: '',
      author: '',
      btnText: '',
      btnLink: '',
      homeTeam: '',
      homeFlag: null,
      visitingTeam: '',
      visitingFlag: null,
      fee1: '',
      feeX: '',
      fee2: '',
      // status: true,
    }

    const types = ref([
      {
        name: 'Casino',
        value: 'Casino',
      },
      {
        name: 'Deporte',
        value: 'Deporte',
      },
    ])
    const listConfigValue = ref(null)
    const listConfig = ref([])

    const refInputHomeFlag = ref(null)
    const refInputVisitingFlag = ref(null)

    const form = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      form.value = JSON.parse(JSON.stringify(blankData))
    }

    const loading = ref(false)
    const loadingHomeFlag = ref(false)
    const loadingVisitingFlag = ref(false)

    async function uploadFile(base64, key) {
      if (key === 'homeFlag') loadingHomeFlag.value = true
      if (key === 'visitingFlag') loadingVisitingFlag.value = true
      // isError.value = false

      try {
        const { data } = await GeneralRequest.uploadExtra({ file: base64, folder: 'teams' })

        if (!data.data) {
          throw new Error('Upload file failed')
        }

        form.value[key] = data.data
      } catch (e) {
        console.log(`Error uploading file "${key}"`)
        // isError.value = true
      } finally {
        if (key === 'homeFlag') loadingHomeFlag.value = false
        if (key === 'visitingFlag') loadingVisitingFlag.value = false

        refInputHomeFlag.value.reset()
        refInputVisitingFlag.value.reset()
      }
    }

    const onChangeFile = async (event, key) => {
      if (!event) return

      const base64 = await useFileToBase64(event)

      await uploadFile(base64, key)
    }

    const onSubmit = async () => {
      loading.value = true

      const data = { ...form.value }

      if (data.type.value === 'Deporte') {
        if (!data.homeFlag) {
          throw new Error('Bandera del equipo local requerida')
        }
        if (!data.visitingFlag) {
          throw new Error('Bandera del equipo visitante requerida')
        }
      }

      try {
        emit('update:item', data)
        emit('update:visible', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    function handleFile(key) {
      if (key === 'homeFlag') {
        refInputHomeFlag.value.$refs.input.click()
      }
      if (key === 'visitingFlag') {
        refInputVisitingFlag.value.$refs.input.click()
      }
    }

    function handleImportConfig(val) {
      if (val.value) {
        this.form = JSON.parse(val.value)
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      types,
      listConfigValue,
      listConfig,
      handleImportConfig,

      form,
      loading,
      onSubmit,

      refInputHomeFlag,
      refInputVisitingFlag,

      loadingHomeFlag,
      loadingVisitingFlag,

      onChangeFile,
      handleFile,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
