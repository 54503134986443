import http from './http'
import Resource from './resource'

const path = 'admin/banners'

class Banners extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  destroyImage(bannerId, opt = {}) {
    return this.http.delete(`api/${path}/${bannerId}/image`, opt)
  }

  storeImage(data, opt = {}) {
    return this.http.post('api/admin/files', data, opt)
  }

  duplicate(id) {
    return this.http.post(`api/${path}/${id}/duplicate`)
  }
}

const BannersRequest = new Banners(http)

export default BannersRequest
