import http from './http'

class General {
  constructor(_http) {
    this.http = _http
  }

  uploadExtra(data, opt = {}) {
    return this.http.post('api/admin/upload/extra', data, opt)
  }

  getChannel() {
    return this.http.get('api/v1/user/channel')
  }

  getCommand(id) {
    return this.http.get(`api/v1/commands/${id}`)
  }
}

const GeneralRequest = new General(http)

export default GeneralRequest
