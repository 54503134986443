<template>
  <b-img
    v-if="!src"
    blank
    :blank-color="blankColor"
    :width="width"
    :height="height"
  />

  <div
    v-else-if="src.startsWith('<svg')"
    :width="width"
    :height="height"
    v-html="src"
  />

  <b-img
    v-else
    :src="src"
    :alt="alt"
    :width="width"
    :height="height"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'Image',
    },
    width: {
      type: String,
      default: '24px',
    },
    height: {
      type: String,
      default: '24px',
    },
    blankColor: {
      type: String,
      default: '#f4f4f4',
    },
  },
}
</script>
