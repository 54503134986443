import BannersRequest from '@/@api/banners'
import { ref, computed } from '@vue/composition-api'
import moment from 'moment-timezone'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUBannerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    // { key: 'invoiceStatus', sortable: true },
    { key: 'position', label: 'Posición', sortable: true },
    {
      key: 'name',
      label: 'Banner',
      sortable: true,
    },
    { key: 'type', label: 'Tipo', sortable: true },
    { key: 'countries', label: 'Paises', sortable: true },
    { key: 'languages', label: 'Idiomas', sortable: true },
    { key: 'placeToShow', label: 'Donde mostrar', sortable: true },
    { key: 'classes', label: 'Clases', sortable: true },
    { key: 'status', label: 'Estado', sortable: true },
    {
      key: 'issuedDate',
      label: 'Desde - Hasta',
      sortable: true,
      thStyle: {
        minWidth: '220px',
        width: '220px',
      }
    },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBanners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const typeItem = ref('')
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const categoriesFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUBannerListTable.value ? refUBannerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBanners.value,
    }
  })

  const refetchData = () => {
    refUBannerListTable.value.refresh()
  }

  debouncedWatch([typeItem, currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, categoriesFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchBanners = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      paginate: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value,
      plan: planFilter.value,
      status: statusFilter.value ? statusFilter.value.value : null,
      categories: categoriesFilter.value
        ? categoriesFilter.value.map(c => c.id ?? c)
        : categoriesFilter.value,
    }

    if (typeItem.value) {
      params.type = typeItem.value
    }

    BannersRequest.list({ params })
      .then(response => {
        const { data, total } = response.data.data

        const list = data.map(banner => {
          const startDate = banner.start_date ? moment(banner.start_date).tz('America/Santiago').format('YYYY-MM-DD hh:mm a') : ''
          const finishDate = banner.finish_date ? moment(banner.finish_date).tz('America/Santiago').format('YYYY-MM-DD hh:mm a') : ''
          let statusVariant = 'success'
          let statusExpired = 0

          if (banner.status && banner.finish_date) {
            const currentDate = moment().tz('America/Santiago')
            const date = moment(banner.finish_date).tz('America/Santiago')

            const days = date.diff(currentDate, 'days')
            const hours = date.diff(currentDate, 'hours')

            if (days <= 2) {
              statusVariant = 'warning'
              statusExpired = `Expira en ${days} d: ${hours} h`
            }
          } else {
            statusVariant = 'danger'
          }

          return {
            ...banner,
            type: banner.type ? banner.extra_config?.type.name : '',
            issuedDate: `${startDate} - ${finishDate}`,
            countries: banner.countries ? banner.countries.map(country => country.name) : [],
            placeToShow: banner.extra_config?.placeToShow?.map(place => place.text) ?? [],
            classes: banner.extra_config?.placeToShow?.map(place => `.${place.value}`) ?? [],
            languages: banner.extra_config?.urls?.map(url => url?.lang?.label) ?? [],
            status: {
              text: banner.status ? 'Publicado' : 'No publicado',
              variant: statusVariant,
              expired: statusExpired,
            },
            loading: false,
          }
        })

        callback(list)
        totalBanners.value = total
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching banners list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function bannerShow(id) {
    try {
      const { data } = await BannersRequest.show(id)

      return data.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async function bannerStore(body) {
    try {
      const { data } = await BannersRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function bannerUpdate(id, body) {
    try {
      const { data } = await BannersRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function bannerDelete(id) {
    try {
      const { data } = await BannersRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function duplicateBanner(id) {
    try {
      const { data } = await BannersRequest.duplicate(id)
      console.log(data)
      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function imageDelete(bannerId, opt = {}) {
    try {
      const { data } = await BannersRequest.destroyImage(bannerId, opt)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function bannerFileUpload(file) {
    try {
      const { data } = await BannersRequest.storeImage(file)

      if (!data.data.url) {
        throw new Error('Upload file failed')
      }

      return data.data.url
    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    fetchBanners,
    tableColumns,
    perPage,
    currentPage,
    totalBanners,
    dataMeta,
    perPageOptions,
    typeItem,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUBannerListTable,

    refetchData,
    bannerShow,
    bannerStore,
    bannerUpdate,
    bannerDelete,
    duplicateBanner,
    imageDelete,
    bannerFileUpload,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    categoriesFilter,
  }
}
