<template>
  <div
    :class="{ 'border-danger bg-danger bg-accent-1': !isExistCountry }"
    class="border rounded p-2 bg-grey"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-1">
        Imagenes inválidas
      </h4>

      <div>
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          size="sm"
          class="mt-0 ml-2"
          @click="onRemoveItem"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>Eliminar</span>
        </b-button>
      </div>
    </div>

    <div style="display: grid; grid-template-columns: repeat(3, minmax(30%, 1fr)); grid-gap: 20px;">
      <b-media
        v-for="file in item.files"
        :key="file.name"
        no-body
        vertical-align="center"
        class="flex-column p-1 align-items-center rounded-lg bg-white position-relative"
        style="border: 1px solid lightgray"
      >
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          size="sm"
          class="position-absolute btn-icon"
          style="top: 6px; right: 6px"
          @click="onRemoveImage(file)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
        <b-media-aside class="mx-auto">
          <b-img
            ref="refPreviewEl"
            :src="file.file || file.url"
            height="110"
            width="170"
            class="rounded mb-1 mb-md-0"
          />
        </b-media-aside>
        <b-media-body class="d-flex flex-column align-items-center">
          <!-- <b-card-text class="my-50 text-center">
            <b-link id="blog-image-text">
              {{ resolveResolution(file.type) }} {{ resolveIsoLang(file.lang) }}
            </b-link>
          </b-card-text> -->
          <!-- <b-card-text
            v-if="resolveIsoLang(file.lang) === file.lang"
            class="my-50 text-center"
          >
            <b-link
              class="d-block"
              @click="!disabled ? isAddNewUserSidebarActive = true : null"
            >
              <span class="text-danger">Crear idioma</span>
            </b-link>
          </b-card-text> -->
          <small class="my-2 h6">{{ file.name }}</small>
          <!-- <div class="d-inline-block">
            <b-form-file
              ref="refInputEl"
              :disabled="disabled"
              accept=".jpg, .png, .webp"
              placeholder="Choose file"
              @input="onChangeFile($event, file)"
            />
          </div> -->
          <!-- <div
            class="d-flex justify-content-between align-items-center w-100 my-1"
          >
            <label
              :for="file.name + '-check-auth'"
              class="m-0 w-100"
            >Requiere autenticación</label>
            <b-form-checkbox
              :id="file.name + '-check-auth'"
              v-model="file.requiredAuth"
              :disabled="disabled"
              switch
            />
          </div> -->
        </b-media-body>
      </b-media>
    </div>

    <div class="mt-3 font-weight-bold">
      Nota: Estas se eliminarán al enviar el formulario.
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBToggle, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import { useInputImageRenderer, useFileToBase64 } from '@core/comp-functions/forms/form-utils'
// import { useRouter } from '@/@core/utils/utils'
// import useBanners from './useBanners'
import useCountries from '../countries/useCountries'
import useLangs from '../langs/useLangs'
// import LangsAddNew from '../langs/LangsAddNew.vue'
// import CountryAddNew from '../countries/CountryAddNew.vue'

export default {
  components: {
    BButton,
    BMedia,
    // BCardText,
    BMediaAside,
    BMediaBody,
    BImg,
    // BFormFile,
    // BLink,
    // BFormCheckbox,
    // LangsAddNew,
    // CountryAddNew,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  model: {
    prop: 'item',
    event: 'removeItem',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup({ item }, { emit }) {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const isAddNewCountrySidebarActive = ref(false)

    const {
      countries,
      fetchCountriesSelector,
      resolveIsoCountry,
      resolveResolution,
    } = useCountries()
    const {
      langs,
      fetchLangsSelector,
      resolveIsoLang,
    } = useLangs()

    const isExistCountry = computed(() => resolveIsoCountry(item.country_iso) !== item.country_iso)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    const onChangeFile = async (file, el) => {
      const base64 = await useFileToBase64(file)

      const index = item.files.findIndex(i => i.name === el.name)

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        el.file = base64
      }
    }

    async function onRemoveItem() {
      emit('removeItem')
    }

    async function onRemoveImage(file) {
      emit('removeImage', file.name)
    }

    function getFileName(txt) {
      if (txt.length < 20) return txt

      const split = txt.split('_')
      const name = `${split.shift().slice(0, 9)}...`

      return `${name}${split.join('_')}`
    }

    function refetchLangs() {
      fetchLangsSelector().then(list => {
        langs.value = list
      })
    }

    function refetchDataCountries() {
      fetchCountriesSelector().then(list => {
        countries.value = list
      })
    }

    return {
      resolveIsoCountry,
      resolveResolution,
      resolveIsoLang,

      onChangeFile,
      onRemoveImage,
      onRemoveItem,

      getFileName,
      inputImageRenderer,

      isAddNewUserSidebarActive,
      isAddNewCountrySidebarActive,
      isExistCountry,
      refetchLangs,
      refetchDataCountries,
    }
  },
}
</script>
